
.contact-text-questions{
  background-color: #2F4F4F;
  padding-bottom: 2%;
  padding-top: 2%;
  color: aliceblue;
  text-align: center;
}  
   
   .contact-window-form {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 600px;
    height: 100%;
    padding-top: 50px;
    position: relative;
  }
  
  /* .contact-text-questions {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  } */
  
  .contact-form-label {
    font-weight: bold;
    color: black;
  }
  
  .contact-form-control {
    border-radius: 3px;
    border: 1px solid gray;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .btn-form-submit {
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .btn-form-submit:hover {
    background-color: gray;
  }


  @media screen and (max-width: 600px) {
    .contact-text-questions {
      font-size: 20px;
    }
  
    .contact-form-control {
      padding: 8px;
    }
  
    .btn-form-submit {
      font-size: 14px;
      padding: 8px 16px;
    }
  } 
  