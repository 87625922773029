.home,
.our-team,
.learn-more,
.contact-us {
  display: flex;
  height: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3rem;

}

.our-team {
  background-image: url('../../Images/Refyne-Banner.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.learn-more {
  background-image: url('../../Images/Refyne-Banner.jpeg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  background-image: url('../../Images/Refyne-Banner.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}