.CSV-File-Selector {

    font-size: 1.5rem;
    width: 200px;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: 5px 1px 10px 1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(rgba(93, 194, 119, 1), rgba(138, 219, 83, 1));
}

.CSV-File-Selector:hover {
    background: linear-gradient(rgb(81, 184, 107), rgb(117, 204, 60));
}

.CSV-File-Selector:disabled {
    background: linear-gradient(rgb(49, 113, 65), rgb(74, 128, 37));
}

.CSV-File-Selector-Remove {

    font-size: 1.5rem;
    width: 200px;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: 5px 1px 10px 1px rgba(0, 0, 0, 0.2);
    background: #E64B4B;
    background: linear-gradient(130deg, #9D3333 0%, #E64B4B 100%);
    margin-right: 50px;
}

.CSV-File-Selector-Remove:hover {
    background: linear-gradient(130deg, #892d2d 0%, #cc4343 100%);
}

.upload-btn-wrapper input[type=file] {
    font-size: 20rem;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-btn-wrapper {
    position: absolute;
    right: 30px;
    overflow: hidden;
    display: inline-block;
    box-shadow: 5px 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.CSV-top-bar {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    width: 100%;
    height: 100px;
    border-radius: 15px;
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.2);
}

.progress-bar {
    position: absolute;
    display: inline-block;
    bottom: 0;
    overflow: hidden;
    width: 100%;

}

.import-csv-image {

    height: 60px;
    width: 60px;

}

.import-csv-image-container {
    position: absolute;
    display: inline-block;
    left: 20px;
    overflow: hidden;
}

.import-csv-text {
    position: absolute;
    display: inline-block;
    left: 90px;
    overflow: hidden;
    font-size: 1.5rem;

}

.import-csv-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.2);
}