.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-icon {
    font-size: 20px;
    color: darkgray;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.tooltip-content {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    padding: 8px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
}