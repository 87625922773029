.window {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  background-color: #e6e6e6;
  padding-top: 0px;
  margin-top: 80px;
}

.form-label {
  color: black;
}

.refyne-logo {
  width: 200px;
  height: auto;
  margin-bottom: 2rem;
}

.window-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 500px;
  padding: 2rem;
  max-height: 30%;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.form-label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}

.btn-submit {
  background-color: #504a4b;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #c82333;
}