.flexContainerEmail {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}
.emailImportPage {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    overflow: hidden;
}

.titleMessage {
    font-size: 3rem;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
}

.homePageSafeArea {
    position: relative;
    text-align: left;
    padding-top: 0%;
    padding-bottom: 2%;
        padding-left: 100px;
        padding-right: 2%;
        width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    overflow-y: scroll;
}


.emailImportForm {
    display: flexbox;
    flex-direction: column;
    margin-right: 5px
}

.emailImportFormRight {
    display: flexbox;
    flex-direction: column;
}


.form-input {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    display: inline-block;
    width: 22rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-input:focus {
    outline: none;
}

.email-button {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-top: 10px;
    margin-bottom: 10px;
}

.email-button:hover {
    background: rgba(74, 74, 74, 1)
}

.email-button:disabled,
.email-button[disabled] {
    opacity: .25;
}
.import-email-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 1rem;
    background-color: #fff;
    width: 90%;
    height: 200px;
    border-radius: 15px;
    box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.2);
}

.import-email-top-row-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
}