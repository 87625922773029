.messages-page-safe-area {
    background-color: #fafafa;
    font-family: "AvenirLTProRoman";
    padding-top: 0%;
    padding-bottom: 2%;
    padding-left: 100px;
    padding-right: 2%;
    height: 100%;
    width: 100%;
    position: absolute;

}

.messages-page-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 10px;
}

.top-container {
    position: relative;
    min-height: 100px;
    max-height: 110px;
    width: 100%;

}

.folders {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    height: 100%;
    width: 12rem;
    float: left;

}

.inbox-title-container {
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    width: 100%;
    text-align: left;
    position: absolute;
    top: 0px;
    left: 100px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}


.labels-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    height: 30%;
    width: 200px;
    position: relative;
    margin-top: -22px;
}



.messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 25rem;
    background-color: #ebe9e9;
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    z-index: 0;

}

.search-bar-container {
    margin-top: 20px;
}

.search-bar {
    font-size: 18px;
    padding: 10px;
    padding-top: 14px;
    border-radius: 15px;
    margin-top: 5px;
    width: 90%;
    border: 0px solid #DBDBDB;
    -webkit-box-shadow: inset 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: inset 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: inset 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    background-color: #DBDBDB;
}

.search-bar:focus {
    outline: none;
}

.message-list {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 10px;

}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100% !important;
    max-height: 100%;

    flex-grow: 1;
}

.hr-line {
    border: 1px solid #DBDBDB;
    width: 85%;
    align-self: left;
    margin-left: 0;
    position: absolute;
    bottom: 0;
}

.hr-line-full {
    border: 1px solid #DBDBDB;
    width: 100%;
    align-self: left;
    margin-left: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
}

.hr-line-message {
    border: 1px solid #DBDBDB;
    width: 95%;
    align-self: center;
    margin-left: 5%;
    position: absolute;
    bottom: 0;
}

.add-label-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    background-image: -webkit-linear-gradient(top, #f5f5f5, #dedede);
    background-image: -moz-linear-gradient(top, #f5f5f5, #dedede);
    background-image: -ms-linear-gradient(top, #f5f5f5, #dedede);
    background-image: -o-linear-gradient(top, #f5f5f5, #dedede);
    background-image: linear-gradient(to bottom, #f5f5f5, #dedede);
    -webkit-border-radius: 9;
    -moz-border-radius: 9;
    border-radius: 9px;
    -webkit-box-shadow: 0px 3px 8px rgb(0, 0, 0, .24);
    -moz-box-shadow: 0px 3px 8px rgb(0, 0, 0, .24);
    box-shadow: 0px 3px 8px rgb(0, 0, 0, .24);
    color: #000000;
    font-size: 10px;
    padding: 2px 2px 2px 2px;
    text-decoration: none;
    width: 246px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 15px;
}

.add-label-container:hover {
    background: #e6e6e6;
    background-image: -webkit-linear-gradient(top, #e6e6e6, #c7c7c7);
    background-image: -moz-linear-gradient(top, #e6e6e6, #c7c7c7);
    background-image: -ms-linear-gradient(top, #e6e6e6, #c7c7c7);
    background-image: -o-linear-gradient(top, #e6e6e6, #c7c7c7);
    background-image: linear-gradient(to bottom, #e6e6e6, #c7c7c7);
    text-decoration: none;
}

.add-label-container:active {
    background: #d3d2d2;
    background-image: -webkit-linear-gradient(top, #d0d0d0, #b0aeae);
    background-image: -moz-linear-gradient(top, #d0d0d0,
            #b0aeae);
    background-image: -ms-linear-gradient(top, #d0d0d0,
            #b0aeae);
    background-image: -o-linear-gradient(top, #d0d0d0,
            #b0aeae);
    background-image: linear-gradient(to bottom, #d0d0d0,
            #b0aeae);
    text-decoration: none;
}





.form-input-small-label-selector {
    font-size: 15px;
    border-width: 2px;
    border-style: solid;
    padding: 8px;
    padding-top: 8px;
    border-radius: 10px;
    width: 246px;
    border-color: #000000;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
    outline: none;
}

.form-input-small-label-selector:hover {
    outline: none;
}