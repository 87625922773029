.message-flex-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2.5rem;
}

.message-top-flex-container {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    flex-wrap: nowrap;
    min-height: 75px;
    max-height: 75px;
    padding-left: 2.5rem;
    width: 100%;
    margin-top: 25px
}

.message-date-container {
    max-width: 50%;
    margin-top: 12px;
}

.message-date {
    font-size: 1rem;
    color: #666666;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;

}

.toolbar-button {
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}


.toolbar-button:hover {
    filter: drop-shadow(rgba(0, 0, 0, 0.33) 0px 1px 4px);
}

.message-content-flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    height: 100%;
    max-height: 100%;
    width: 100%;
}

.message-title-container {
    width: 100%;
}

.message-title {
    font-size: 2rem;
    width: 100%;
    margin-bottom: -10px;
}

.message-content-container {
    display: inline-block;
    height: 95%;
    width: 100%;
    text-align: left;
}

.message-content {
    font-size: 1.2rem;
    width: 100%;
    height: 93%;
    margin-bottom: 1rem;
    overflow-y: scroll;
}

.message-attributes-wrapper {
    position: relative;


}

.message-attributes-flex-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    width: 40rem;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0px;

}

.message-attribute {
    font-size: 1.2rem;
    border-radius: 5px;
    margin-bottom: 19px;
    text-align: center;
    height: 1.6rem;
}

.attribute-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    height: 10%;
    margin-right: 3px;


}

.attribute-label {
    font-size: 1rem;
    margin-left: 0;
    margin-top: -22px;
    color: #666666;
    align-self: flex-start;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}