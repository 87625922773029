.signUpPage {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    overflow: hidden;
}

.homePageSafeArea {
    position: relative;
    text-align: left;
    padding-left: 15%;
    width: auto;
    height: 100%;
    background-color: #F2F2F2;
}

.titleMessage {
    font-size: 3rem;
    margin-top: 100px;
    width: 100%;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}

.signUpForm {
    display: flexbox;
    flex-direction: column;
    margin-top: 50px;
}

.signUpFormRight {
    display: flexbox;
    flex-direction: column;
    margin-right: 25%;

}

.art {
    display: flexbox;
    width: 340px;
    margin-right: 20%;
    align-self: flex-start;
    max-height: 330px;
    min-width: 250px;
    margin-top: 50px;

}

.form-input {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    display: inline-block;
    width: 22rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-input-small-container {
    width: 22rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.form-input-small {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 7rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-input-medium-city {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 14rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 1rem;
}

.form-input-medium {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 22rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 1rem;
}

.form-input-zip {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 7rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;

}

.form-input-address-container {
    width: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.form-input-address:focus {
    outline: none;
}

.form-input:focus {
    outline: none;
}

.form-input-small:focus {
    outline: none;
}

.form-input-zip:focus {
    outline: none;
}

.form-input-medium-city:focus {
    outline: none;
}

.form-input-medium:focus {
    outline: none;
}

.errorText {
    color: red;
}

.nextButton {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-bottom: 40px;
}

.nextButton:hover {
    background: rgba(74, 74, 74, 1)
}

.nextButton:disabled,
.nextButton[disabled] {
    opacity: .25;
}

.mediumButton {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-bottom: 40px;
}

.mediumButton:hover {
    background: rgba(74, 74, 74, 1)
}

.mediumButton:disabled,
.mediumButton[disabled] {
    opacity: .25;
}