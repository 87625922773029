.label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 20%;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: -10px;
}

.label-container-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 20%;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: -10px;
    -webkit-animation: transformLabel .2s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: transformLabel .2s cubic-bezier(.39, .575, .565, 1.000) both
}

@-webkit-keyframes transformLabel {
    0% {
        border-radius: 9px;
    }

    100% {
        background-color: #F2F2F2;
        border-radius: 9px;
        -webkit-box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    }
}

@keyframes transformLabel {
    0% {
        border-radius: 9px;
    }

    100% {
        background-color: #F2F2F2;
        border-radius: 9px;
        -webkit-box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    }
}

.label-icon {
    height: 40px !important;
    width: 50px !important;
    min-width: 50px !important;
    margin-right: 5px;
    margin-left: 0px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 1px 4px);
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.label-text {
    overflow-x: scroll;
    text-overflow: clip;
    white-space: nowrap;
    margin-bottom: 0px !important;
    margin-top: 9px !important;
    color: black;

}

.label-text-container {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: -5px;
    word-wrap: break-word;
    overflow: hidden;
    height: 40px;
    text-align: left;

}