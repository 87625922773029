@font-face {
    font-family: "AvenirLTProRoman";
    src: local("AvenirLTProRoman"),
        url("../../Fonts/AvenirLTProRoman.otf") format("truetype");
}

.homePage {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;


}

.homePageRightSide {
    position: absolute;
    text-align: left;
    padding-left: 9rem;
    top: 0;
    bottom: 0;
    right: 0;
    left: 4rem;
    height: 100vh;
    width: auto;
    background-color: #F2F2F2;
}

.welcomeMessage {
    font-size: 2rem;
    margin-top: 36px;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    -webkit-animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
    background: #4DC3FF;
    background: -webkit-linear-gradient(to bottom, #4DC3FF 0%, #3C89FB 100%);
    background: -moz-linear-gradient(to bottom, #4DC3FF 0%, #3C89FB 100%);
    background: linear-gradient(to bottom, #4DC3FF 0%, #3C89FB 100%);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    width: 82%;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    color: white;
}

.welcomeMessageNone {
    font-size: 2.4rem;
    margin-top: 36px;
    opacity: 0;
    min-height: 57.59px;

}

.cardsSection {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 3rem;
    flex-wrap: wrap;

}

/*
.cardsSection {
    margin-top: 4.6rem;
    display: grid;
    grid-template-columns: repeat(2, 19rem);
    gap: 3rem;
}*/

.overlay {
    height: 200%;
    width: auto;
    position: absolute;
    top: 0;
    left: 4rem;
    opacity: 80%;
    background-color: black;
    color: white;
    z-index: 10;
}

.profileSection {
    position: absolute;
    top: 2.4rem;
    right: 7vw;
    height: 50px;
    width: 50px;
    padding: -50px;
    border-radius: 100%;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
}

.profilePicture {
    object-fit: contain;
    border-radius: 50%;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

.profilePicture:hover,
.profilePicture:focus,
.profilePicture:active {
    border-radius: 50%;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@media (max-width: 330px) {

    .greyMenu {
        width: 3rem;
        background-color: grey;
        height: 100vh;
    }

    .whiteMenu {
        width: 1.5rem;
        left: 0.75rem;
    }

    .homePageRightSide {
        width: 84vw;
        left: 3rem;
        padding-left: 0;
        padding-top: 0;
        height: 100vh;
    }

    .welcomeMessage {
        font-size: 2rem;
        width: 45vw;
    }

    .notificationSection {
        margin-top: 3rem;
        overflow-x: hidden;
    }

    .profileSection {
        height: 40px;
        width: 40px;
        top: 30px;
        right: -10px;
    }

}

@media (max-width: 420px) {
    .greyMenu {
        width: 3rem;
        height: 100%;
        background-color: grey;
    }

    .whiteMenu {
        width: 1.5rem;
        margin: 0 auto;
        left: 0.75rem;
    }

    .homePageRightSide {
        width: 84vw;
        left: 3rem;
        padding-left: 20px;
        padding-top: 0;
        height: 100%;
    }

    .notificationSection {
        margin-top: 3rem;
        overflow-x: hidden;
    }

    .welcomeMessage {
        width: 11rem;
    }
}

@media (max-width: 750px) {
    .greyMenu {
        background-color: grey;
        height: 100vh;
    }

    .homePageRightSide {
        padding-left: 2rem;
        height: 100vh;
    }

    .welcomeMessage {
        max-width: 18rem;
    }

    .notificationSection {
        max-height: 60vh;
        grid-template-columns: repeat(1, 19rem);
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

@media (max-width: 844px) and (min-width: 751px) {
    .greyMenu {
        background-color: grey;
        height: 100vh;
        width: 3rem;
    }

    .whiteMenu {
        width: 1.5rem;
        left: 0.75rem;
        margin: 0 auto;
    }

    .homePageRightSide {
        padding-left: 1rem;
        height: 100vh;
        left: 3rem;
    }

    .notificationSection {
        grid-template-columns: repeat(2, 19rem);
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}


@media (max-width: 1154px) and (min-width: 845px) {
    .greyMenu {
        background-color: grey;
        height: 100vh;
    }

    .homePageRightSide {
        padding-left: 2rem;
        height: 100vh;
    }

    .notificationSection {
        max-height: 60vh;
        overflow-y: scroll;
        grid-template-columns: repeat(2, 19rem);
        overflow-x: hidden;
    }

}

@media (max-width: 1250px) and (min-width: 1155px) {
    .greyMenu {
        height: 100vh;
    }

    .homePageRightSide {
        padding-left: 4rem;
        width: 100%;
        height: 100vh;
    }

}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-28 22:27:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}