.message-preview-container {
    display: inline-block;
    width: 25rem;
}

.message-preview-flex-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 5rem;
    width: 96%;
    background-color: #ebe9e9;
    flex-wrap: nowrap;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 9px;
}

.message-preview-flex-container:hover {
    background-color: #d7d6d6;
    border-radius: 9px;
}

.message-preview-flex-container-selected {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 5rem;
    width: 96%;

    flex-wrap: nowrap;
    border-radius: 9px;
    margin-left: 8px;
    margin-right: 8px;
    -webkit-animation: transformMessagePreview .4s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: transformMessagePreview .4s cubic-bezier(.39, .575, .565, 1.000) both
}

@-webkit-keyframes transformMessagePreview {
    0% {}

    100% {
        background-color: #ffffff;
    }
}

@keyframes transformMessagePreview {
    0% {}

    100% {
        background-color: #ffffff;
    }
}

.message-preview-left {
    display: inline-block;
    position: relative;
    width: 75%;
    height: 100%;
}

.message-preview-right {
    display: inline-block;
    position: relative;
    width: 25%;
    height: 100%;
}

.message-preview-label-container {
    bottom: -2px;
    left: 0px;

    position: absolute;
    width: 100%;
    left: 5px;
    padding-left: 5px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #666666;
}

.message-preview-body-container {

    padding-left: 10px;
    padding-bottom: 10px;

    top: 5px;
    left: -2px;
    padding-top: -4px;

    position: absolute;
    width: 100%;
    text-align: left;
    height: 1rem;
}

.message-preview-body {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.3;
}

.message-preview-notifcation-container {
    top: 3px;
    right: 0px;
    position: absolute;
    width: 100%;
    text-align: right;
    height: 1rem;
    padding-right: 10px;
}


.message-preview-notification {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(230, 75, 75);
    background: radial-gradient(circle, rgba(230, 75, 75, 1) 0%, rgba(230, 33, 33, 1) 100%);
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
}

.message-preview-time-container {
    bottom: 12px;
    right: 0px;
    position: absolute;
    width: 100%;
    text-align: right;
    height: 1rem;
    padding-right: 10px;


}

.message-preview-time {
    font-size: 1rem;
}

.hidden {
    display: none;
}