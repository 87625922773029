.signUpPage {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;
    background: #4DC3FF;
    background: -webkit-linear-gradient(to top, #4DC3FF 0%, #FBFBFB 100%);
    background: -moz-linear-gradient(to top, #4DC3FF 0%, #FBFBFB 100%);
    background: linear-gradient(to top, #4DC3FF 0%, #FBFBFB 100%);

}

.body {
    padding: 0px;
}

.homePageSafeAreaSignUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: top;
    padding-top: 30px;
    height: 100%;
    width: 100%;
}

.titleMessageSignIn {
    font-size: 2rem;

    width: 100%;
    height: 75px;
    align-self: center;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.titleMessageContainerSignIn {
    width: 100%;
    text-align: center;
    color: #afafaf;
    height: 75px;
    margin-bottom: 0px;
    margin-top: 20px;
}

.flexContainerSignIn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* center all child elements*/
    align-content: center;
    margin-top: 0%;
    max-width: 90%;
    min-width: 200px;
    width: 400px;
    max-height: 90%;
    min-height: 356px;
    ;
    height: 600px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.20);
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.20);

    border-radius: 15px;
    padding: 2rem;
    background-color: #ffffff;
}

.signInLogoIcon {
    height: 100%;
    width: 100%;
}

.formContainer {
    height: 225px;

}

.signInLogoIconContainer {
    height: 75px;
    width: 225px;
    margin-bottom: 0px;
    margin-top: 25px;
}

.signUpForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    width: 100%;

}

.signUpFormRight {
    display: flex;
    flex-direction: column;
    margin-right: 25%;

}

.art {
    display: flexbox;
    width: 340px;
    margin-right: 20%;
    align-self: flex-start;
    max-height: 330px;
    min-width: 250px;
    margin-top: 50px;

}

.formInputSignUp {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    display: inline-block;
    width: 22rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
    box-shadow: inset 0px 0px 8px 0px rgba(42, 42, 42, .15);
    -webkit-box-shadow: inset inset 0px 0px 8px 0px rgba(42, 42, 42, .15);
    -moz-box-shadow: inset inset 0px 0px 8px 0px rgba(42, 42, 42, .15);
    ;
}

.form-input-small-container {
    width: 22rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.form-input-small {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 7rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-input-medium-city {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 14rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 1rem;
}

.form-input-medium {
    font-size: 18px;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    padding-top: 12px;
    border-radius: 10px;
    width: 22rem;
    border-color: #666666;
    text-shadow: 0px 0px 0px rgba(42, 42, 42, .15);
    box-shadow: 0px 3px 8px 0px rgba(42, 42, 42, .15);
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 1rem;
}

.form-input:focus {
    outline: none;
}

.form-input-small:focus {
    outline: none;
}

.form-input-zip:focus {
    outline: none;
}

.form-input-medium-city:focus {
    outline: none;
}

.form-input-medium:focus {
    outline: none;
}

.errorText {
    color: red;
}

.nextButton {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-bottom: 30px;
    margin-top: 10px;

}

.nextButton:hover {
    background: rgba(74, 74, 74, 1)
}

.nextButton:disabled,
.nextButton[disabled] {
    opacity: .25;
}

.mediumButton {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-bottom: 40px;
}

.mediumButton:hover {
    background: rgba(74, 74, 74, 1)
}

.mediumButton:disabled,
.mediumButton[disabled] {
    opacity: .25;
}