.react-map-gl-popup-tip {
    display: none;
  }
  
.react-map-gl-popup-content {
border-radius: 40px;
background: none;
}

.react-map-gl .mapboxgl-popup {
    background: transparent;
    box-shadow: none;
}
  