.settings-page {
    background-color: #F2F2F2;
    font-family: "AvenirLTProRoman";
    padding-top: 0%;
    padding-bottom: 2%;
    padding-left: 100px;
    padding-right: 2%;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
}

.settings-page-title {
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    width: 100%;
    text-align: left;
    position: absolute;
    top: 0px;
    left: 100px;
}

.settings-page-container {
    display: flex;
    flex-direction: row;
    align-items: left;
    align-content: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: #F2F2F2;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 90px;
}

.manage-subscription-button {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 22rem;
    height: 50px;
    border-width: 0px;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 1);
    background: rgba(102, 102, 102, 1);
    margin-bottom: 40px;
}

.manage-subscription-button:hover {
    background: rgba(74, 74, 74, 1)
}