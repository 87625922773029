.cardSection {
    height: 14rem;
    width: 19rem;
    min-width: 256px;
    background-color: white;
    border: 0.6 solid black;
    border-radius: 16px;
    padding-left: 0.7rem;
    box-shadow: 0 3px 10px rgb(0 0 0/0.4);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;

}

.cardSection:hover,
.cardSection:focus,
.cardSection:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}

.cardTitle {
    margin-top: 16px;
    font-size: 1.6rem;
}

.rightNotificationHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rightNotificationNumber {
    font-size: 0.9rem;
    color: rgb(162, 160, 160);
}

.notificationRedCircle {
    border-radius: 100%;
    background-color: red;
    height: 1rem;
    width: 1rem;
    margin-left: 1.2rem;
}

.cardMessageSection {
    margin-top: 0;
    letter-spacing: 0;
    width: 90%;
    height: 40%;
    overflow-y: none;
}

.lastImportNotification {
    letter-spacing: 0;
    font-size: 11px;
    margin-bottom: 3rem;
}

.cardIcon {
    height: 2.5rem;
    margin-bottom: 3rem;
}

.cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-right: 1rem;
    padding-bottom: 1rem;
}

@media(max-width: 330px) {
    .cardSection {
        width: 12rem;
    }

    .cardTitle {
        font-size: 1.2rem;
    }
}

@media (max-width: 360px) and (min-width: 331px) {
    .cardSection {
        width: 15rem;
    }
}

@media(max-width: 420px) and (min-width: 361px) {
    .cardSection {
        width: 16rem;
        padding-left: 10px;
    }
}

@media (max-width: 770px) and (min-width: 421px) {
    .cardSection {
        width: 17rem;
    }
}