.importPageSafeArea {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: #f2f2f2;
    padding-left: 5rem;
    overflow: visible;
}

.import-title-container {
    width: 90%;
    margin: 0 auto;
    text-align: left;
}

.import-title {
    font-size: 2.5rem;
    width: 100%;
    margin-bottom: -10px;
}

.import-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    height: 37rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.52);
    border-radius: 24px;
    padding: 2rem;
}

.import-container .import-title-container {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;

    text-align: left;
}

.import-container .import-title-container-text {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: grey
}