.twitter-picker {
    box-shadow: none !important;
    border-radius: 12px !important;
}

.popover {
    position: absolute;
    height: 560px;
    width: 276px;
    z-index: 2;
    top: 100px;
    right: 0px;
    bottom: 0px;

    background-color: white;
    border-radius: 12px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    -moz-box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
}

.cover {
    position: 'fixed';
    top: '0px';
    right: '0px';
    bottom: '0px';
    left: '0px';
}

.labels-container-add-label {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    height: 246px;
    width: 95%;
    position: relative;
    margin-left: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 10px;
    padding-top: 2px;
}

.nextButton-small {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 246px;
    height: 30px;
    border-width: 0px;
    color: white;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    background: #666666;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */


}

.nextButton-small-green {
    font-family: "AvenirLTProRoman";
    letter-spacing: 1px;
    font-size: 15px;
    width: 246px;
    height: 30px;
    border-width: 0px;
    color: white;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    background: rgb(93, 194, 119);
    background: linear-gradient(130deg, rgba(93, 194, 119, 1) 0%, rgba(138, 219, 83, 1) 100%);
    margin-bottom: 10px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */


}

.nextButton-small:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
}

.nextButton-small-green:disabled {
    background: #E64B4B;
    background: linear-gradient(130deg, #9D3333 0%, #E64B4B 100%);
}

.nextButton-small-green:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
}

.label-title-container {
    text-align: left;
    margin-left: 15px;
}

.hr-line-add-label-container {
    position: relative;
    width: 100%;
}