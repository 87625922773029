.import-card-container {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ebeaea;
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    padding: 1rem;
    margin-bottom: 2rem;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

.import-card-image-container {
    width: 4rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.import-card-image {
    width: 4rem;
    height: 4rem;

    object-fit: cover;

}

.import-card-text-container {
    width: 80%;
    height: 4rem;
    text-align: left;
}

.import-card-text {
    font-size: 2rem;
    margin-left: 30px;
    font-weight: 100;

}

.import-card-container:hover,
.import-card-container:focus,
.import-card-container:active {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
}