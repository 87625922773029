.insights-page-safe-area {
    background-color: #F2F2F2;
    font-family: "AvenirLTProRoman";
    padding-top: 0%;
    padding-bottom: 2%;
    padding-left: 100px;
    padding-right: 2%;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
}

.insights-page-container {
    display: flex;
    flex-direction: row;
    align-items: left;
    align-content: flex-start;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    background-color: #F2F2F2;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 90px;
}

.insights-page-title {
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    width: 100%;
    text-align: left;
    position: absolute;
    top: 0px;
    left: 100px;
}

.insights-card-container-medium {
    align-items: center;
    justify-content: center;
    height: 300px;
    min-width: 300px;
    padding: 20px;
    width: 500px;

    background-color: #ffffff;
    border-radius: 16px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
}

.insights-card-container-small {
    height: 140px;
    padding: 20px;
    width: 150px;
    position: relative;
    background-color: #ffffff;
    border-radius: 16px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
}

.insights-card-small-flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 300px;


}

.insights-card-small-flex-container-filled {
    padding: 20px;
    height: 300px;
    background-color: #ffffff;
    border-radius: 16px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    width: 325px;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.insights-card-container-square {
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 16px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
}

.insights-card-container-pie {
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 20px;
    width: 400px;
    background-color: #ffffff;
    border-radius: 16px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
}

.pie-chart-container {
    align-items: center;
    justify-content: center;
    height: 325px;
    width: 400px;
    margin-left: 15px;
    margin-top: -50px;
    padding: 10px;
}

.bar-chart-container {
    height: 375px;
    width: 400px;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.insights-card-title {
    text-align: left;
    margin-top: -15px;
    font-size: .6rem;
    color: #666666;
}

.insights-card-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
}

.dropdown-container {
    font-size: 0.9rem;
    height: 50px;
    width: 120px;
    margin-top: 0px;
    text-align: left;
}

.date-picker {
    width: 90px;
}

.label-dropdown-container {
    font-size: 0.9rem;
    height: 50px;
    width: 200px;
    margin-top: -7px;
    position: absolute;
    top: 40px;
    right: 70px;
    display: flex;
    text-align: left;
}

.label-dropdown-text {
    font-size: 1.3rem;
    margin-right: 25px;
    margin-top: 9px;
}

.insights-card-value-container {
    position: absolute;
    vertical-align: text-top;
    text-align: center;
    width: 110px;
    height: 80px;
    bottom: 20px;
    color: rgb(55, 54, 54);
    vertical-align: text-top;

}

.insights-card-value-container-fitted {
    position: absolute;
    vertical-align: text-top;
    text-align: center;
    width: 100px;
    height: 80px;
    bottom: 20px;
    color: rgb(55, 54, 54);
    vertical-align: text-top;
    font-size: 22vw;
    padding: 1px;
}


.insights-card-value {
    display: inline;
    height: 50px;
    color: rgb(55, 54, 54);
    vertical-align: text-top;
}

.insights-card-value-text {
    margin-block-start: 0;
    margin-block-end: 0;
    display: inline;
    font-size: 3.5rem;
    vertical-align: text-top;
    text-align: center;
}

.insights-card-value-text-fitted {
    margin-block-start: 30;
    margin-block-end: 0;
    font-size: 140%;
    vertical-align: middle;
    text-align: center;
    margin-top: 23px;
}


.insights-card-value-word {
    display: inline;
    color: rgb(55, 54, 54);
    vertical-align: text-top;
    text-align: center;
}

.insights-card-value-text-words {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.4vw;


}

.data-table-container {
    background-color: white;
    width: 97.3%;
    border-radius: 16px !important;
    padding: 15px;
    -webkit-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 10px 6px 0px rgba(0, 0, 0, 0.16);
    text-align: left;
    margin-bottom: 10px !important
}