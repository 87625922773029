@font-face {
    font-family: "AvenirLTProRoman";
    src: local("AvenirLTProRoman"),
        url("../../Fonts/AvenirLTProRoman.otf") format("truetype");
}

.container {
    background: #F2F2F2;
    height: 800px;
    width: 400px;
    padding-top: 20px;
}

.div_select {
    display: flex;
    justify-content: space-between
}

.message_header {
    font-family: "AvenirLTProRoman";
}

.message_select {
    font-family: "AvenirLTProRoman";
    background-color: grey;
    color: white;
    border-radius: 15px;
    margin-left: 50px;
    line-height: 4px;
    width: 100px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    background-image: linear-gradient(to right, #666666, #9E9E9E)
}

.below_header {
    font-family: "AvenirLTProRoman";
    font-size: 15px;
    margin-right: 50px;
    font-style: italic;
    margin-top: 4px;

}

.message_form {
    font-family: "AvenirLTProRoman";
    margin-top: 20px;
    width: 300px;
    height: 200px;
    color: #000000;
    font-size: 14px;
    text-align: left;
    border-radius: 20px;
    padding: 10px 20px;
    resize: none;
}

.SendButton {
    font-family: "AvenirLTProRoman";
    margin-top: 20px;
    background-color: green;
    color: white;
    padding-top: 10px;
    width: 300px;
    font-size: 35px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #5DC277, #8ADB53);
    border: 0ch;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

.SendButton:hover,
.SendButton:focus,
.SendButton:active {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}