.visual-map-page-safe-area {
    background-color: #F2F2F2;
    font-family: "AvenirLTProRoman";
    padding-top: 0%;
    padding-bottom: 2%;
    padding-left: 100px;
    padding-right: 2%;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
}